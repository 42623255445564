<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <!-- <template slot="header">
          <div class="row">
            <div class="col-6 text-right">
              <notification-subscription
                v-if="$currentUserCan($permissions.PERM_VIEW_ANY_OPTIONS)"
                :objectType="'options'"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                }"
              />
            </div>
          </div>
        </template> -->

        <option-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import OptionListTable from "./partials/OptionListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    OptionListTable,
    [Button.name]: Button,
    // NotificationSubscription,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {},
};
</script>
